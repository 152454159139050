import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css'; 
import DropdownMenu from './DropdownMenu';

function Portfolio() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <div>Please log in to view the portfolio.</div>;
    }

    const holdings = [
        { equities: "SoFi Technologies", ticker: "SOFI", dividendYield: "--", costBasis: "$39,997", reinvestedDividends: "--", cashDividends: "--", marketValue: "$74,651", portfolioPercent: "17.9%", totalgainloss: "87%" },
        { equities: "AGNC Investment Corp", ticker: "AGNC", dividendYield: "13.8%", costBasis: "$51,644", reinvestedDividends: "$11,909", cashDividends: "$360", marketValue: "$63,610", portfolioPercent: "15.2%", totalgainloss: "24%" },
        { equities: "AST SpaceMobile", ticker: "ASTS", dividendYield: "--", costBasis: "$21,294", reinvestedDividends: "--", cashDividends: "--", marketValue: "$54,220", portfolioPercent: "13.0%", totalgainloss: "155%" },
        { equities: "Pinterest", ticker: "PINS", dividendYield: "--", costBasis: "$30,089", reinvestedDividends: "--", cashDividends: "--", marketValue: "$48,740", portfolioPercent: "11.7%", totalgainloss: "62%" },
        { equities: "Comtech Telecommunications Corp", ticker: "CMTL", dividendYield: "--", costBasis: "$54,950", reinvestedDividends: "--", cashDividends: "--", marketValue: "$34,000", portfolioPercent: "8.1%", totalgainloss: "(38%)" },
        { equities: "PubMatic", ticker: "PUBM", dividendYield: "--", costBasis: "$49,995", reinvestedDividends: "--", cashDividends: "--", marketValue: "$28,414", portfolioPercent: "6.8%", totalgainloss: "(43%)" },
        { equities: "Micron Technology", ticker: "MU", dividendYield: "0.5%", costBasis: "$24,775", reinvestedDividends: "--", cashDividends: "--", marketValue: "$24,344", portfolioPercent: "5.8%", totalgainloss: "(2%)" },
        { equities: "Alphabet", ticker: "GOOGL", dividendYield: "0.5%", costBasis: "$19,940", reinvestedDividends: "--", cashDividends: "$24", marketValue: "$20,434", portfolioPercent: "4.9%", totalgainloss: "3%" },         
        { equities: "B. Riley Financial", ticker: "RILY", dividendYield: "--", costBasis: "$8,496", reinvestedDividends: "--", cashDividends: "--", marketValue: "$15,547", portfolioPercent: "3.7%", totalgainloss: "83%" },
        { equities: "Equities Total", ticker: "--", dividendYield: "2.9%", costBasis: "$301,179", reinvestedDividends: "$11,909", cashDividends: "$384", marketValue: "$363,958", portfolioPercent: "87.2%", totalgainloss: "21%" },
        { equities: "Cash", ticker: "--", dividendYield: "--", costBasis: "--", reinvestedDividends: "--", cashDividends: "--", marketValue: "$53,407", portfolioPercent: "12.8%", totalgainloss: "--" },
        { equities: "Portfolio Value", ticker: "--", dividendYield: "2.7%", costBasis: "$332,000", reinvestedDividends: "--", cashDividends: "--", marketValue: "$417,365", portfolioPercent: "100.0%", totalgainloss: "26%" }
    ];


    return (
        <div className="container mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Portfolio Holdings (as of 2/28/25)</h3>
                <DropdownMenu />  
            </div>    
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Equities</th>
                        <th>Ticker</th>
                        <th>Dividend Yield</th>
                        <th>Cost Basis</th>
                        <th>Market Value</th>
                        <th>% of Portfolio</th>
                        <th>Total % gain/loss</th>
                    </tr>
                </thead>
                <tbody>
                    {holdings.map((holding, index) => (
                        <tr key={index}>
                            <td>{holding.equities}</td>
                            <td>{holding.ticker}</td>
                            <td>{holding.dividendYield}</td>
                            <td>{holding.costBasis}</td>
                            <td>{holding.marketValue}</td>
                            <td>{holding.portfolioPercent}</td>
                            <td>{holding.totalgainloss}</td>
                        </tr>
                    ))}
                </tbody>
            </table>            
        </div>
    );
}

export default Portfolio;
